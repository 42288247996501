import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceAreaFour = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div
        className='service-area bg-cover pd-top-120 pd-bottom-90 pd-top-110 pd-bottom-90'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>WHAT WE OFFER</h6>
                <h2 className='title'>
                  It <span>Service</span> Refers To The Provision Of Support
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-4'>
                  <img src='assets/img/icon/18.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Web Development</h5>
                  <p className='content mb-3'>
                    Embark on a journey of exploration, delving deep into the realm of web development.
                    Uncover new horizons as you navigate through the intricacies of design and functionality,
                    shaping digital landscapes with precision and creativity.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/27.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>DevOps Solution</h5>
                  <p className='content mb-3'>
                    Forge ahead into the realm of DevOps, where innovation meets efficiency.
                    Harness the power of automation and collaboration to sculpt seamless workflows,
                    empowering teams to deliver software solutions with speed and precision.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/28.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>SalesForce Solution</h5>
                  <p className='content mb-3'>
                    Elevate your business capabilities with SalesForce solutions.
                    Seamlessly integrate cutting-edge technologies to optimize customer engagement and drive transformative growth,
                    unlocking the full potential of your sales ecosystem.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/29.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>SASS Solutions</h5>
                  <p className='content mb-3'>
                    Embark on a digital shopping spree through the vibrant marketplace of SaaS solutions.
                    Discover an array of powerful tools and services tailored to elevate your operations,
                    offering streamlined efficiency and unparalleled scalability.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFour;
